export const GET_CLIENTES_USUARIO = 'GET_CLIENTES_USUARIO';
export const LOGIN_USUARIO = 'LOGIN_USUARIO';
export const LOGOUT_USUARIO = 'LOGOUT_USUARIO';
export const GET_SERVICIOS_TO_CLIENT = 'GET_SERVICIOS_TO_CLIENT';
export const GET_TAREAS_TO_SERVICIO = 'GET_TAREAS_TO_SERVICIO';
export const START_LOADING_SERVICIOS = 'START_LOADING_SERVICIOS';
export const FINISH_LOADING_SERVICIOS = 'FINISH_LOADING_SERVICIOS';
export const START_LOADING_CLIENTES = 'START_LOADING_CLIENTES';
export const FINISH_LOADING_CLIENTES = 'FINISH_LOADING_CLIENTES';
export const START_LOADING_TAREAS = 'START_LOADING_TAREAS';
export const FINISH_LOADING_TAREAS = 'FINISH_LOADING_TAREAS';   
export const LIMPIAR_SERVICIOS = 'LIMPIAR_SERVICIOS';
export const LIMPIAR_TAREAS = 'LIMPIAR_TAREAS';
export const CARGAR_HORAS = 'CARGAR_HORAS';
export const CARGAR_HORAS_EXITO = 'CARGAR_HORAS_EXITO';
export const CARGAR_HORAS_ERROR = 'CARGAR_HORAS_ERROR';
export const ACTUALIZAR_HORAS = 'ACTUALIZAR_HORAS';
export const CARGAR_HORAS_USUARIO = 'CARGAR_HORAS_USUARIO';
export const ACTUALIZAR_HORAS_USUARIO_LOGIN = 'ACTUALIZAR_HORAS_USUARIO_LOGIN';
export const RESET_STATE = 'RESET_STATE';
export const RESETEAR_HORAS = 'RESETEAR_HORAS';
export const ELIMINAR_HORAS = 'ELIMINAR_HORAS';
export const GET_TAREAS_DEFAULT = 'GET_TAREAS_DEFAULT';
export const SET_TAREAS_DEFAULT = 'SET_TAREAS_DEFAULT';
export const LIMPIAR_HORAS = 'LIMPIAR_HORAS';
export const ACTUALIZAR_ESTADO_TARJETAS = 'ACTUALIZAR_ESTADO_TARJETAS';
export const OBTENER_HORAS = 'OBTENER_HORAS';
export const GET_TODAS_TAREAS = 'GET_TODAS_TAREAS';
export const SET_HORAS_REPORTE = 'SET_HORAS_REPORTE';  
export const SET_USUARIOS = 'SET_USUARIOS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLEAR_HORAS = 'CLEAR_HORAS';
export const DATO_USUARIO = 'DATO_USUARIO';
export const GET_TODOS_CLIENTES = 'GET_TODOS_CLIENTES';
export const GET_TODAS_AREAS = 'GET_TODAS_AREAS';
export const HABILITAR_HORAS = 'HABILITAR_HORAS';
export const OBTENER_ESTADO_HORAS_HABILITADAS= 'OBTENER_ESTADO_HORAS_HABILITADAS';
//export const CREAR_CLIENTE= 'CREAR_CLIENTE';
//export const GET_SERVICIOS_TO_AREA_CLIENT= 'GET_SERVICIOS_TO_AREA_CLIENT';