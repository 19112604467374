import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm.jsx';


// Define el componente LoginPage
const LoginPage = () => {
  const token = useSelector(state => state.session ? state.session.token : null);

  if (token) {
    return <Redirect to="/home" />;
  }

  return (
    <>
   
    <video autoPlay muted loop playsInline id="myVideo">
        <source src="/images/Login_Web.mp4" type="video/mp4" />
      </video>
    <LoginForm />
  </>
  );
};

export default LoginPage;