import './login.css';
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUsuario } from '../../actions/index.js';
//import  { RESET_STATE } from '../../actions/actionstypes.js';
import './loginerror.css';

// Define el componente LoginForm
const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const authError = useSelector(state => state.auth && state.auth.error);

  //Estado local para controlar la visualización del mensaje de error
  const [showError, setShowError] = useState(false);

  //este useEffect se ejecuta cada vez que authError cambia
  //es decir, cada vez que se produce un error en el inicio de sesión
  useEffect(() => {
    // Si authError cambia y no es null, muestra el mensaje de error
    if (authError) {
      setShowError(false); // Primero, oculta el mensaje de error
      setTimeout(() => setShowError(true), 100); // Luego, después de un breve retraso, vuelve a mostrarlo
    }
  }, [authError]);

  // Función para manejar el envío del formulario del inicio de sesión
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUsuario(email, password));
  };


  return (
    <div className='formlogin'>
      <img src={require('../../images/logo-expansion-blanco.svg').default} alt="Logo" className="logo" />
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '30px', alignItems: 'center' }}>
      
        <div className='inputBox'>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
          <span>Usuario</span>
        </div>
        <div className='inputBox'>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
          <span>Contraseña</span>
        </div>
        {showError && <div className="error">{authError}</div>}
        <button className='boton-inicio' type="submit" >Iniciar sesión</button>
      </form>
      
    </div>
  );
};

export default LoginForm;