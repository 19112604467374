// Importar los tipos de acciones
import { GET_CLIENTES_USUARIO,LOGIN_USUARIO,LOGOUT_USUARIO,
  GET_SERVICIOS_TO_CLIENT,GET_TAREAS_TO_SERVICIO,CLEAR_HORAS,DATO_USUARIO,
  START_LOADING_SERVICIOS, FINISH_LOADING_SERVICIOS,START_LOADING_CLIENTES,FINISH_LOADING_CLIENTES,
  START_LOADING_TAREAS,LIMPIAR_HORAS,SET_USUARIOS,SET_HORAS_REPORTE,LOGIN_ERROR,GET_TODOS_CLIENTES,
  FINISH_LOADING_TAREAS,LIMPIAR_SERVICIOS,LIMPIAR_TAREAS,ACTUALIZAR_ESTADO_TARJETAS,GET_TODAS_AREAS,OBTENER_ESTADO_HORAS_HABILITADAS,
  CARGAR_HORAS, CARGAR_HORAS_EXITO, CARGAR_HORAS_ERROR, ACTUALIZAR_HORAS,OBTENER_HORAS,HABILITAR_HORAS,
  CARGAR_HORAS_USUARIO,ACTUALIZAR_HORAS_USUARIO_LOGIN,ELIMINAR_HORAS,GET_TAREAS_DEFAULT,SET_TAREAS_DEFAULT, GET_TODAS_TAREAS,
} from '../actions/actionstypes';


// Definir el estado inicial
const initialState = {
    // Propiedades iniciales del estado
    
    clientes: [],
    servicios: [],
    tareas : [],
    tareasToservicio: [],
    horas: [],
    horasReporte: [],
    session: {
        token: localStorage.getItem('token'),
        id: null, // Agrega esto
        perfil: null, //
        // otras propiedades de la sesión
      },
      usuario: {},
      horasHabilitadas: null,
      clientesCargando: false,
      serviciosCargando: false,
      tareasCargando:false,
      loading: false,
      error: null,
      data: null,
     tareasDefault: [],
     usuarios: [],
     clientesReporte: [],
     Repoteareas: [],
     auth: {
      error: null,
  },
 

    

   

};

// Definir el reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENTES_USUARIO:
            return {
                ...state,
                clientes: action.payload, // Aquí actualizamos el estado clientes con los datos que vienen en action.payload
            };
        case GET_TODOS_CLIENTES:
          return{
            ...state,
            clientesReporte: action.payload
          };
          case GET_TODAS_AREAS:
            return{
              ...state,
              Repoteareas: action.payload
            };

         case GET_SERVICIOS_TO_CLIENT:
              return {
                ...state,
                servicios: action.payload
         };
         case GET_TAREAS_TO_SERVICIO:
                return {
                  ...state,
                  tareasToservicio: action.payload
      };
      case HABILITAR_HORAS:
        return {
            ...state,
            horasHabilitadas: action.payload,
        };
        case OBTENER_ESTADO_HORAS_HABILITADAS:
          return {
              ...state,
              horasHabilitadas: action.payload,
          };

         case LOGIN_USUARIO:
                return {
                    ...state,
                    session: {
                      ...state.session,
                      token: action.payload,
                      id: action.payload.id,
                      perfil: action.payload.perfil
                    }
                };
                case DATO_USUARIO:  
                   return {  
               ...state,
                   usuario: action.payload.usuario
                    };
                case LOGIN_ERROR:
                  return {
                    ...state,
                    auth: {
                      ...state.auth,
                      error: action.payload,
                    },
                  };
                  case LOGOUT_USUARIO:
                    const horasHabilitadas = state.horasHabilitadas; // Guarda el valor actual de horasHabilitadas
                    return {
                      ...initialState,
                      horasHabilitadas, // Asigna el valor guardado a horasHabilitadas
                      session: {
                        ...initialState.session,
                        token: null
                      }
                    };
         case START_LOADING_SERVICIOS:
                      return {
                        ...state,
                        serviciosCargando: true,
                      };
                
          case FINISH_LOADING_SERVICIOS:
                      return {
                        ...state,
                        serviciosCargando: false,
                      };

         case START_LOADING_CLIENTES:
                        return {
                            ...state,
                            clientesCargando: true,
                        };
                    
          case FINISH_LOADING_CLIENTES:
                        return {
                            ...state,
                            clientesCargando: false,
                        };
          case START_LOADING_TAREAS:
                          return {
                              ...state,
                              tareasCargando: true,
                          };
                      
          case FINISH_LOADING_TAREAS:
                          return {
                              ...state,
                              tareasCargando: false,
                          };
          case LIMPIAR_SERVICIOS:
                            return {
                              ...state,
                              servicios: [], // limpia los servicios
                            };
          case LIMPIAR_TAREAS:
                              return {
                                ...state,
                                tareas: [], // limpia los servicios
                              };
          case CARGAR_HORAS:
            return { ...state, loading: true, error: null };

            case CARGAR_HORAS_EXITO:
              const nuevasHoras = {
                ...state.horas,
                [action.payload.cardId]: [...(state.horas[action.payload.cardId] || []), action.payload],
              };
              
              return {
                ...state,
                loading: false,
                horas: nuevasHoras,
              };
              case CARGAR_HORAS_USUARIO:
                return {
                  ...state,
                  horas: {
                   
                    ...action.payload
                  }
                };

          case CARGAR_HORAS_ERROR:
                                return { ...state, loading: false, error: action.payload };                      
                                
                                case ACTUALIZAR_HORAS:
                                  return {
                                      ...state,
                                      horas: {
                                          ...state.horas,
                                          [action.payload.id]: action.payload
                                      }
                                  }; 
                                  case ACTUALIZAR_HORAS_USUARIO_LOGIN:
                                    return {
                                      ...state,
                                      horas: {
                                        ...state.horas,
                                        [action.payload.fecha]: action.payload.nuevasHoras,
                                      },
                                    };

                                    case ELIMINAR_HORAS:
                                      const horasActualizadas = { ...state.horas };
                                      delete horasActualizadas[action.payload];
                                      return {
                                        ...state,
                                        horas: horasActualizadas,
                                      };
    case GET_TAREAS_DEFAULT:
      return {
        ...state,
        tareasDefault: action.payload
      };
      case SET_TAREAS_DEFAULT:
        return {
          ...state,
          tareas: state.tareasDefault
        };

    case GET_TODAS_TAREAS:
      return {
        ...state,
        tareas: action.payload,
        loading: false
      };
      case LIMPIAR_HORAS:
  return {
    ...state,
    horas: {}// o cualquier valor inicial que desees para horas
  };
  case ACTUALIZAR_ESTADO_TARJETAS:
    return {
        ...state,
        ...action.payload // Esto asume que payload es un objeto con los nuevos estados
    };
    case OBTENER_HORAS:
      return {
        ...state,
        horas: action.payload,
      };
      case SET_USUARIOS:
        return { ...state, usuarios: action.payload };
        case SET_HORAS_REPORTE:
          return { ...state, horasReporte: action.payload };
        case CLEAR_HORAS:
      return [];
   
 /* case RESETEAR_HORAS:
  return {horas : {}, loading: false, error: null}
  */
 

              default:
                return state;
    }
};

 

export default reducer;