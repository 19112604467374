import React, { useState, useEffect } from 'react';
import FormularioCargaHora from '../cardCargaDehora/FormularioCargaHora.jsx';
import './carddia.css';
import { useSelector, useDispatch } from 'react-redux';
import { actualizarHorasUsuariologin, eliminarHoras, getTodasTareas} from '../../actions/index.js';
import Modal from 'react-modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.jsx'; // Importa el componente LoadingSpinner
import './../Modal/Modal.css';
import swal from 'sweetalert';
Modal.setAppElement('#root');

function CardDia({ dia, fecha }) {
  // Usa el hook useDispatch para obtener la función dispatch de Redux
  const dispatch = useDispatch();

  // Define varios estados locales usando el hook useStatev
  const [formularioVisible, setFormularioVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const tareas = useSelector(state => state.tareas || []);
  const clientes = useSelector(state => state.clientes || []);
  const tareasDefault = useSelector(state => state.tareasDefault);
 // const usuario = useSelector(state => state.session);
  const horasLocalStorage = useSelector(state => state.horas || []);
  const [horasEditando, setHorasEditando] = useState({});
  const [idHorasEditando, setIdHorasEditando] = useState(null);


// Realiza algunas operaciones para obtener la fecha actual y la fecha de la tarjeta
const [diaFecha, mes, ano] = fecha.split('/');
const fechaTarjeta = new Date(ano, mes - 1, diaFecha);
const fechaActual = new Date();
const horasHabilitadas = useSelector(state => state.horasHabilitadas);
// Calcular mes y año anterior
const mesActual = fechaActual.getMonth();
const anoActual = fechaActual.getFullYear();
const mesAnterior = mesActual === 0 ? 11 : mesActual - 1;
const anoAnterior = mesActual === 0 ? anoActual - 1 : anoActual;
// Verificar si es el mes actual o el anterior
const esMesActualOAnterior = (fechaTarjeta.getMonth() === mesActual && fechaTarjeta.getFullYear() === anoActual) || (fechaTarjeta.getMonth() === mesAnterior && fechaTarjeta.getFullYear() === anoAnterior);
// Usa el hook useEffect para realizar una acción cuando el componente se monta
useEffect(() => {
    const fetchTareas = async () => {
      try {
        setLoading(true); // Establecer loading en true antes de iniciar la carga de datos
        //await dispatch(getTodasTareas());// Cargar tareas para mostrar en el formulario
        if(tareas.length >= 0){
          setLoading(false); // Establecer loading en false después de cargar los datos
        }
      } catch (error) {
        console.error(error);
        setLoading(false); // Establecer loading en false en caso de un error
      }
    };
  
    fetchTareas();
  
    return () => {
      console.log('El componente se ha desmontado');
    };
  }, [dispatch]);

//funcion para editar horas
const handleEditar = (horasParaEditar) => {
    setHorasEditando(prevHorasEditando => ({
      ...prevHorasEditando,
      [horasParaEditar.id]: horasParaEditar
    }));// Guardar las horas a editar en el estado local
    setIdHorasEditando(horasParaEditar.id);
    setFormularioVisible(true);
  };

// en las cards de los dias se muestran las horas cargadas en el dia
  const handleCargarHoras = async (horasParaCargar) => {
    try {
      const nuevasHorasCargadas = [...horasLocalStorage, horasParaCargar];
    
      // Actualizar horas en el estado global
      await dispatch(actualizarHorasUsuariologin(fecha, nuevasHorasCargadas));
    
      setFormularioVisible(false);
      setHorasEditando(prevHorasEditando => {
        const newHorasEditando = { ...prevHorasEditando };
        delete newHorasEditando[horasParaCargar.id];
        return newHorasEditando;
        
      });
      setLoading(true); // Establecer loading en false después de cargar los datos correctamente
      dispatch(getTodasTareas()); // Forzar la actualización de los datos
      setTimeout(() => {
        setLoading(false); // Establecer loading en false después de cargar los datos
      }, 1000); //
    } catch (error) {
      console.error(error);
      setLoading(false); // Establecer loading en false en caso de un error
    }
  };

//funcion para cerrar el modal
  const handleClose = () => {
    setFormularioVisible(false);
    setHorasEditando({});
    setIdHorasEditando(null);
  };

//funcion para eliminar horas
const handleEliminar = async (idParaEliminar) => {
    // Confirmación antes de eliminar
    swal({
      className: "textoAlerta",
      title: "¿Desea eliminar este registro?",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "alertaBtn1", // Aquí puedes agregar una clase para estilizar el botón
          closeModal: true,
        },
        confirm: {
          text: "Si, eliminar",
          value: true,
          visible: true,
          className: "alertaBtn2", // Aquí puedes agregar una clase para estilizar el botón
          closeModal: true
        }
      },
     
    })
    .then(async (willDelete) => {
      if (willDelete) {
        setLoading(true); // Establecer loading en true antes de iniciar la eliminación
        await dispatch(eliminarHoras(idParaEliminar));
        const nuevasHorasCargadas = horasLocalStorage.filter(horas => horas.id !== idParaEliminar);
        await dispatch(actualizarHorasUsuariologin(fecha, nuevasHorasCargadas));
        setTimeout(() => {
          setLoading(false); // Establecer loading en false después de cargar los datos
        }, 1000); //
      }
    });
  };

 /* const handleFormSubmit = () => {
    setLoading(true); // Cambia el estado de carga a true cuando se envía el formulario
  };
*/
  return (
    <div className='carddia'>
      <h2>{dia}</h2>
      <h2>{fecha}</h2>
      {loading 
      ? (
       
        <LoadingSpinner /> // Muestra el indicador de carga mientras los datos se están cargando
      ) : (
        // Si los datos ya se han cargado, muestra el contenido
        <>
          <p className='totalhoras'>Horas del día: {
              // Si hay horas en el almacenamiento local, suma todas las horas del día
            Array.isArray(horasLocalStorage) ? 
              horasLocalStorage.filter(hora => hora.fechaCarga === fecha)
              .reduce((total, hora) => total + Number(hora.horas), 0)
              : 0 // Si no hay horas en el almacenamiento local, muestra 0
            }
          </p>
          <button className='BotonAgregarHora' onClick={() => setFormularioVisible(true)} disabled={!horasHabilitadas && !esMesActualOAnterior}>Agregar hora</button>
          {Array.isArray(horasLocalStorage) && horasLocalStorage.filter(hora => hora.fechaCarga === fecha).length > 0 ? (
            horasLocalStorage.filter(hora => hora.fechaCarga === fecha).map((hora, index) => {
               // Busca la tarea, el cliente y la tarea por defecto correspondientes a la hora
              const tarea = tareas.find(t => t && t && t.id === hora.tareaId);
              const cliente = clientes.find(c => c && c && c.id === hora.clienteId);
              const tareaDefault = tareasDefault.find(td => td.id === hora.tareaId);
                // Obtiene los nombres de la tarea, el cliente y la tarea por defecto
              const nombreTarea = tarea?.nombre;
              const nombreCliente = cliente?.nombre;
              const nombreTareaDefault = tareaDefault?.nombre;
              // Muestra los detalles de la hora
              return (
                <div key={index}>
                  {nombreCliente && <p> {nombreCliente}</p>}
                  
                  <p> {nombreTarea ? nombreTarea : nombreTareaDefault}</p>

                  <p>{hora.horas}hs</p>

                  <button className='btnEdit' onClick={() => { handleClose(); handleEditar(hora); }}>Editar</button>
                  <button className="btnElim" onClick={() => handleEliminar(hora.id)}>Eliminar</button>
                  <div className='separador'>____________________</div>
                </div>
              );
            })
          ) : (
              // Si no hay horas en el almacenamiento local para el día, muestra un mensaje
            <p className='nohayhoras'>No hay horas cargadas</p>
          )}

          <Modal 
            isOpen={formularioVisible} 
            onRequestClose={handleClose} 
            className="Modal__content" 
            overlayClassName="Modal__overlay"
          >
            <FormularioCargaHora 
              onSubmit={handleCargarHoras} 
              onClose={handleClose} 
              horas={horasEditando[idHorasEditando]} 
              isEditing={!!horasEditando[idHorasEditando]}
              fecha={fecha}
             // onFormSubmit={handleFormSubmit} // Pasar la función de callback al formulario
            />
          </Modal>
   
        </>
      )}
    </div>
  );
}

export default CardDia;