import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { habilitarHoras, obtenerHoras } from '../../actions/index.js';
import './Navbar.css';

function Navbar({ user, handleLogout }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const horasHabilitadas = useSelector(state => state.horasHabilitadas);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  const handleHabilitarHoras = () => {
    dispatch(habilitarHoras(!horasHabilitadas));
  };

  return (
    <div className="navbar">
      <div className="logo-container">
        <div className="logo">
          <img src={require('../../images/isologo-expansion-blanco.svg').default} alt="Logo" className="isologo" />
        </div>
      </div>
      <div className="user-info">
        {user && user.nombre && <div className="user-name">{user.nombre}</div>}
        {user && user.apellidos && <div className="user-surname">{user.apellidos}</div>}
      </div>
      <div className="nav-links-container">
        <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <li>
            <NavLink to="/home" exact activeClassName="active">
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink to="/Reportes" activeClassName="active">
              Reportes
            </NavLink>
          </li>
          {user && user.perfil === 'Administrador del sistema' && (
            <>
              <li>
                <button className="nav-button" onClick={handleHabilitarHoras}>
                  {horasHabilitadas ? 'Deshabilitar horas' : 'Habilitar horas'}
                </button>
              </li>
            
            </>
          )}
          <li>
            <NavLink to="/" onClick={handleLogout} activeClassName="active">
              Cerrar sesión
            </NavLink>
          </li>
        </ul>
        <div className="menu-toggle" onClick={handleMenuToggle}>
        <div className={`menu-icon ${isMenuOpen ? 'active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
 
      </div>
    </div>
  );
}

export default Navbar;