import React, { useState, useEffect } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, addWeeks, subWeeks } from 'date-fns';
import CardDia from './../CardDia/cardDia.jsx';
import es from 'date-fns/locale/es';
import './CardSemana.css';
import { useSelector } from 'react-redux';


function Semana() {
  /// Define el estado local semanaActual y su función de actualización setSemanaActual, inicializado con la fecha actual
  const [semanaActual, setSemanaActual] = useState(new Date());
  // Define un array con los nombres de los días de la semana
  const dias = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  // Calcula el inicio y el fin de la semana actual
  const inicioSemana = startOfWeek(semanaActual, { weekStartsOn: 1 });
  const finSemana = endOfWeek(semanaActual, { weekStartsOn: 1 });
  // Crea un array con las fechas de cada día de la semana actual
  const fechas = eachDayOfInterval({ start: inicioSemana, end: finSemana }).map(dia => format(dia, 'dd/MM/yyyy', { locale: es }));
  // Obtiene el ID del usuario actual del estado de Redux
  const usuarioId = useSelector(state => state.session && state.session.id ? state.session.id : null);
  //const [loading, setLoading] = useState(true); 
  
  
  // Obtiene las horas cargadas de la semana actual del estado de Redux
  const horasCargadas = useSelector(state => {
    const horasSemanaActual = {};
    fechas.forEach(fecha => {
      if (state.horas && state.horas[fecha]) {
        horasSemanaActual[fecha] = state.horas[fecha].filter(hora => hora.usuarioId === usuarioId);
      }
    });
    return horasSemanaActual;
  });

  // Define la función para ir a la semana anterior
  const irASemanaAnterior = () => {
    const nuevaSemana = subWeeks(semanaActual, 1);
    setSemanaActual(nuevaSemana);
    localStorage.setItem('semanaActual', nuevaSemana.toISOString());
  };

  // Define la función para ir a la semana siguiente
  const irASemanaSiguiente = () => {
    const nuevaSemana = addWeeks(semanaActual, 1);
    setSemanaActual(nuevaSemana);
    localStorage.setItem('semanaActual', nuevaSemana.toISOString());
  };
  
// Define un efecto para cargar la semana actual guardada en localStorage cuando el componente se monta
  useEffect(() => {
    const semanaGuardada = localStorage.getItem('semanaActual');
    if (semanaGuardada) {
      setSemanaActual(new Date(semanaGuardada));
    }
  }, []);

/* useEffect(() => {
    dispatch(obtenerHoras())
      .then(() => setLoading(false))
      .catch(error => {
        console.error('Error al obtener las horas:', error);
        setLoading(false);
      });
    }, [dispatch]);*/

    // Retorna el JSX para renderizar el componente

  return (
    <div>

      <div className='btnSemana'>
        <button className='btnSemanaIzq' onClick={irASemanaAnterior}>
        <img className='flechaIzq' src={require('../../images/flecha-down.svg').default} alt="Mover Izquierda" />
        </button>
        <h2 className='textSemana'>Semana</h2>
        <button className='btnSemanaDer' onClick={irASemanaSiguiente}>
        <img className='flechaDer' src={require('../../images/flecha-down.svg').default} alt="Mover Derecha" />
        </button>
      </div>
      <div className='cardsemana'>
        {dias.map((dia, index) => (
          <CardDia key={index} dia={dia} fecha={fechas[index]} horasCargadas={horasCargadas[fechas[index]] || []}/>
        ))}
      </div>
    </div>
  );
}

export default Semana;
