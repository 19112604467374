import axios from 'axios';
import { GET_CLIENTES_USUARIO,LOGIN_USUARIO,LOGOUT_USUARIO,GET_SERVICIOS_TO_CLIENT,GET_TAREAS_TO_SERVICIO,
  START_LOADING_SERVICIOS, FINISH_LOADING_SERVICIOS,DATO_USUARIO,GET_TODOS_CLIENTES,
  START_LOADING_CLIENTES,FINISH_LOADING_CLIENTES,LOGIN_ERROR,CLEAR_HORAS,GET_TODAS_AREAS,OBTENER_ESTADO_HORAS_HABILITADAS,
  START_LOADING_TAREAS,LIMPIAR_HORAS,OBTENER_HORAS,SET_USUARIOS,SET_HORAS_REPORTE,HABILITAR_HORAS,
  FINISH_LOADING_TAREAS,LIMPIAR_SERVICIOS,LIMPIAR_TAREAS,ACTUALIZAR_ESTADO_TARJETAS,
   CARGAR_HORAS, CARGAR_HORAS_EXITO, CARGAR_HORAS_ERROR, ACTUALIZAR_HORAS,CARGAR_HORAS_USUARIO,ACTUALIZAR_HORAS_USUARIO_LOGIN,ELIMINAR_HORAS} from './actionstypes.js';

import axiosClient from './.././axiosClient.js';

// Definir la acción

// esta acción se encarga de limpiar las horas en los estados de la aplicación 
// no se esta usando por el momento 
const limpiarHoras = () => {
  return {
    type: LIMPIAR_HORAS
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener todas las tareas
//y tener actualizados las tareas en las card ya cargadas.
const getTodasTareas = () => {
  return async (dispatch) => {
       // Se despacha una acción para indicar que se ha iniciado la carga de las tareas.
    dispatch({ type: START_LOADING_TAREAS });

    try {
    // Se obtiene el token del usuario logueado en el sistema para realizar la petición.
      const token = localStorage.getItem('token');
      // Se realiza la petición GET a la API para obtener todas las tareas.
      const response = await axiosClient.get('/tareas/tareas', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    /// Se despacha una acción para almacenar las tareas obtenidas en el estado de la aplicación.
      dispatch({
        type: 'GET_TODAS_TAREAS',
        payload: response.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: FINISH_LOADING_TAREAS });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener todas las áreas.
// se usa para cargar las areas para el reporte de horas por area
const getTodasAreas = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.get('/horas/areas', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      dispatch({
        type: GET_TODAS_AREAS,
        payload: response.data
      });
    } catch (error) {
      console.error(error);
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener todos los clientes del usuario.
// se usa para cargar los clientes que correspondas con el usuario logueado segun sus areas
const getClientesUsuario = () => {
  return async function(dispatch) {
    dispatch({ type: START_LOADING_CLIENTES });
    const token = localStorage.getItem('token');
    try {
      var json = await axiosClient.get("/clientes/cliente/usuario", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    //  console.log(json.data);
      return dispatch({
        type: GET_CLIENTES_USUARIO,
        payload: json.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: FINISH_LOADING_CLIENTES });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener 
//los clientes para los reportes de clientes
const getTodosClientes = () => {
  return async (dispatch) => {
    dispatch({ type: START_LOADING_CLIENTES });

    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.get('/clientes/cliente', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      dispatch({
        type: GET_TODOS_CLIENTES,
        payload: response.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: FINISH_LOADING_CLIENTES });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener los servicios de un cliente.
// se usa para cargar los servicios que corresponden a un cliente en la picklist de servicios
const getServicios = (clienteId) => {
  return async (dispatch) => {
    dispatch({ type: START_LOADING_SERVICIOS });

    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.get(`/servicios/servicios/cliente/${clienteId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
   //  console.log(response.data);
      dispatch({
        type: GET_SERVICIOS_TO_CLIENT,
        payload: response.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: FINISH_LOADING_SERVICIOS });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener las tareas de un servicio.
//se usa para cargar las tareas que corresponden a un servicio en la picklist de tareas
const getTareas = (servicioId) => {
  return async (dispatch) => {
    dispatch({ type: START_LOADING_TAREAS });
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.get(`/tareas/tareas/servicio/${servicioId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      dispatch({
        type: GET_TAREAS_TO_SERVICIO,
        payload: response.data
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: FINISH_LOADING_TAREAS });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para iniciar sesión en la aplicación.
//y tambien para obtener los datos del cliente logueado
const loginUsuario = (email, password) => {
  return async function(dispatch) {
    try {
      const response = await axiosClient.post("/usuarios/login", {
        email,
        contrasena: password
      });
    //  console.log(response.data);

      const token = response.data.token;
      localStorage.setItem('token', token);

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response2 = await axiosClient.get("/usuarios/usuarios/me", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
 //     console.log('Datos del usuario:', response2.data);

      dispatch({
        type: LOGIN_USUARIO,
        payload: {
          token: token,
          id: response.data.id,
          perfil: response.data.perfil
        }
      });
      dispatch({
        type: DATO_USUARIO,
        payload: {
          usuario: response2.data // Cambia esto
        }
      });

      const horas = JSON.parse(localStorage.getItem(`horas-${response.data.id}`));
      if (horas) {
        dispatch({
          type: CARGAR_HORAS_USUARIO,
          payload: horas
        });
      }
      await  dispatch(obtenerEstadoHorasHabilitadas());
      await dispatch(obtenerHoras());
      await dispatch(getTareasDefault());
      await dispatch(getClientesUsuario());
      await dispatch(getTodasTareas());
      //window.location.reload();
    } catch (error) {
      console.error(error);
      let errorMessage = 'Ocurrió un error al intentar iniciar sesión';
      if (error.response && error.response.status === 401) {
        errorMessage = 'Usuario o contraseña incorrectos';
      }
      dispatch({
        type: LOGIN_ERROR,
        payload: errorMessage
      });
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para cerrar la sesión del usuario.
const logoutUsuario = () => {
  return function(dispatch, getState){
    return new Promise((resolve, reject) => {
      try {
        const usuarioId = getState().session.id;

        // Eliminar los registros de las horas del usuario del localStorage
        localStorage.removeItem(`horas-${usuarioId}`);

        // Limpiar otros datos de sesión
        localStorage.removeItem('token');

        // Limpiar también la semana actual
        localStorage.removeItem('semanaActual');
      
        dispatch({ type: LOGOUT_USUARIO });
        dispatch({ type: LIMPIAR_SERVICIOS }); // acción para limpiar los servicios
        dispatch({ type: LIMPIAR_TAREAS }); // acción para limpiar las tareas
        dispatch({ type: LIMPIAR_HORAS }); // acción para resetear el estado de las horas
       
       //window.location.reload();

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para cargar las horas de un usuario en el formulario.
const cargarHoras = (datos) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    dispatch({ type: CARGAR_HORAS });

    try {
      const { session } = getState();
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/horas/cargarhoras', {
        ...datos,
        senorityhora: session.perfil,
        usuarioId: session.id,
        fechaCarga: datos.fechaCarga,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
    
      dispatch({ type: CARGAR_HORAS_EXITO, payload: { ...response.data, cardId: datos.cardId } });// se despacha la acción para cargar las horas
      dispatch(obtenerEstadoHorasHabilitadas());// se obtiene el estado de las horas habilitadas
      dispatch(obtenerHoras());// se obtienen las horas del usuario para actualizar el estaado de las card y mostrar losdatos en las card ya cargdas
      dispatch(actualizarTodosEstadosTarjetas());// se actualizan los estados de las tarjetas
      resolve(response);// se resuelve la promesa
    } catch (error) {
      dispatch({ type: CARGAR_HORAS_ERROR, payload: error.message });
      reject(error);
    }
  });
};

// Esta es una función de acción de Redux Thunk que se utiliza para actualizar las horas de un usuario
// Es decir, cuando se le da al boton de editar y luego se actualizan las horas
const actualizarHoras = (datosFormulario) => {
  return async function(dispatch){
    try {
      const token = localStorage.getItem('token');
  
      const response = await axiosClient.put(`/horas/actualizarhoras/${datosFormulario.id}`, datosFormulario, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      dispatch({
        type: ACTUALIZAR_HORAS,
        payload: response.data
      });
      dispatch(obtenerEstadoHorasHabilitadas());// se obtiene el estado de las horas habilitadas
      dispatch(obtenerHoras());// se obtienen las horas del usuario para actualizar el estaado de las card y mostrar losdatos en las card ya cargdas
    //  window.location.reload();
  
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para obtener las horas de un usuario.
// es para saber las horas que ha cargado el usuario y esa sean las que se muestren segun el usuario logueado
const obtenerHoras = () => {
  return async function(dispatch, getState) {
    try {
      const usuario = getState().session;
      const habilitarHorasAnteriores = getState().horasHabilitadas; // Obtén el estado del botón desde Redux
      const token = localStorage.getItem('token');
      if (!usuario) {
        throw new Error('El usuario no ha iniciado sesión');
      }
    
      const idUsuario = usuario.id;
      const response = await axiosClient.get(`/horas/horasusuario/${idUsuario}?horasHabilitadas=${habilitarHorasAnteriores}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (Array.isArray(response.data.horasUsuario)) {
        dispatch({
          type: OBTENER_HORAS,
          payload: response.data.horasUsuario
        });

        console.log('Fecha de carga:', response.data.horasUsuario);
      } else {
        console.log('hora o hora.fechaCarga es undefined o no es una fecha válida');
      }
    } catch (error) {
      console.error(error);
    }
  };
};

// Esta es una función de acción de Redux Thunk que se utiliza para actulizar el estado de las horas del usuario logueado
// se usa para sabaer si se esta actualizando una hora o si se esta cargando una hora nueva(el estado horas que se usaen el formulario)
   const actualizarHorasUsuariologin = (fecha, nuevasHoras) => ({
    type: ACTUALIZAR_HORAS_USUARIO_LOGIN,
    payload: { fecha, nuevasHoras }
  });
 /* export const resetearHoras = () => ({
    type: RESETEAR_HORAS,
  });/*/

  // Esta es una función de acción de Redux Thunk que se utiliza para recargar la sesión del usuario.
  // se usa para recargar la sesion del usuario y obtener los datos del usuario logueado al momento de inicar sesion
  const rehydrateSession = (token) => {
    return async function(dispatch, getState){
      try {
        // Configurar el token de autenticación globalmente
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        // Obtener la información del usuario a partir del token
        const response = await axiosClient.get("/usuarios/usuarios/me", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
    
        dispatch({
          type: LOGIN_USUARIO,
          payload: {
            token: token,
            id: response.data.id,
            perfil: response.data.perfil
          }
        });
    
        // Cargar los registros del usuario que está iniciando sesión
        dispatch(obtenerEstadoHorasHabilitadas());
        dispatch(obtenerHoras());
        
         
    
        // Imprimir los datos del usuario y los registros de las tarjetas
     //   console.log('Datos del usuario:', response.data);
        //console.log('Registros de las tarjetas:', getState().horas);
         //dispatch({ type: RESET_STATE });
      } catch (error) {
        console.error(error);
      }
    };
  };
  
  // Esta accion se utiliza para eliminar las horas de un usuario
  const eliminarHoras = (id) => {
    return async function(dispatch){
      try {
        const token = localStorage.getItem('token');
    
        const response = await axiosClient.delete(`/horas/eliminarhoras/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
    
        dispatch({ type: ELIMINAR_HORAS, payload: id });// se despacha la acción para eliminar las horas
        dispatch(obtenerHoras());// se obtienen las horas del usuario para actualizar el estaado de las card y mostrar losdatos en las card ya cargdas
       // window.location.reload();
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  };
  
  // Esta acción se utiliza para obtener las tareas por defecto en la piclist cuando no se selicione un servicio
  const getTareasDefault = () => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get('/tareas/especificas', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        dispatch({
          type: 'GET_TAREAS_DEFAULT',
          payload: response.data
        });
      } catch (error) {
        console.error(error);
      }
    };
    
  };

  //es para setear el estados de las tareas por defecto no se esta usando todavia
   const setTareas = (tareas) => {
    return {
      type: 'SET_TAREAS_DEFAULT',
      payload: tareas
    };
  };

// Esta acción se utiliza para actualizar el estado de las tarjetas si hay algun cambioen los componentes
  const actualizarTodosEstadosTarjetas = (nuevosEstados) => {
    return {
      type: ACTUALIZAR_ESTADO_TARJETAS,
      payload: nuevosEstados
    };
  };

// Esta acción se utiliza para llenar el estado de usuarios
// para la piclist de usaurios para los reportes
  const fetchUsuarios = () => {
    return async (dispatch) => {
   
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get('/usuarios/usuarios', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        dispatch({
          type: SET_USUARIOS,
          payload: response.data
        });
      } catch (error) {
        console.error(error);
      } 
    };
  };
  
  // Esta acción se utiliza para obtener las horas de un usuario en un rango de fechas
  const fetchHoras = (usuarioSeleccionado, fechaDesde, fechaHasta) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get(`/horas/horasusuario/${usuarioSeleccionado}/rango`, {
          params: {
            fechaDesde,
            fechaHasta
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
  
        dispatch({
          type: SET_HORAS_REPORTE,
          payload: response.data
        });
      }catch (error) {
        console.error(error);
        if (error.response) {
          if (error.response.status === 403) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          } else if (error.response.status === 404) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          }
        }
        throw error;
      }
    };
  };

  // Esta acción se utiliza para obtener todas las horas de todos los usuarios en un rango de fechas  
  const fetchTodasHoras = (fechaDesde, fechaHasta) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get(`/horas/horastodosusuarios/rango`, {
          params: {
            fechaDesde,
            fechaHasta
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        dispatch({
          type: SET_HORAS_REPORTE,
          payload: response.data
        });
      } catch (error) {
        console.error(error);
        if (error.response) {
          if (error.response.status === 403) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          } else if (error.response.status === 404) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          }
        }
        throw error;
      }
    };
  };

  // Esta acción se utiliza para obtener las horas de un cliente en un rango de fechas
  const fetchHorasCliente = (clienteSeleccionado, fechaDesde, fechaHasta) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get(`/horas/horascliente/${clienteSeleccionado}/rango`, {
          params: {
            fechaDesde,
            fechaHasta
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  console.log(response.data);
        dispatch({
          type: SET_HORAS_REPORTE,
          payload: response.data
        });
      }catch (error) {
        console.error(error);
        if (error.response) {
          if (error.response.status === 403) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          } else if (error.response.status === 404) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          }
        }
        throw error;
      }
    };
  };

  // Esta acción se utiliza para obtener las horas de un área en un rango de fechas
  const fetchHorasArea = (areaSeleccionada, fechaDesde, fechaHasta) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axiosClient.get(`/horas/horasarea/${areaSeleccionada}/rango`, {
          params: {
            fechaDesde,
            fechaHasta
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        console.log(response.data);
  
        dispatch({
          type: SET_HORAS_REPORTE,
          payload: response.data
        });
      } catch (error) {
        console.error(error);
        if (error.response) {
          if (error.response.status === 403) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          } else if (error.response.status === 404) {
            const customError = new Error(error.response.data.mensaje);
            customError.response = error.response;
            throw customError;
          }
        }
        throw error;
      }
    };
  };

  // Esta acción se utiliza para habilitar o deshabilitar la carga o edicion de horas
  const habilitarHoras = (habilitado) => {
    return async function(dispatch){
      try {
        const token = localStorage.getItem('token');
  
        const response = await axiosClient.put('/horas/actualizarhorashabilitadas', { habilitada: habilitado }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        dispatch({
          type: HABILITAR_HORAS,
          payload: response.data
        });
        dispatch(obtenerEstadoHorasHabilitadas());
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  };

  // Esta acción se utiliza para obtener el estado de las horas habilitadas y llenar el estado
  // y saber si estan habilitados o desabilitados
  export const obtenerEstadoHorasHabilitadas = () => {
    return async function(dispatch){
      try {
        const token = localStorage.getItem('token');
  
        const response = await axiosClient.get('/horas/horashabilitadas', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
  
        dispatch({
          type: OBTENER_ESTADO_HORAS_HABILITADAS,
          payload: response.data.habilitada
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  };

// Esta acción se utiliza para limpiar las horas, no se usa por el momento
 const clearHoras = () => {
    return {
      type: CLEAR_HORAS
    };
  };

// Esta acción se utiliza para limpiar los servicios
//se usa para limpiar el estado de la piclist de servicos para cuando se cierra el modal, para cargar registro
   const limpiarServicios = () => {
    return {
      type: LIMPIAR_SERVICIOS
    };
  };

  
// Exportar la acción
export { fetchUsuarios,limpiarServicios,habilitarHoras,getTodosClientes,getTodasAreas,fetchHorasArea,fetchTodasHoras,fetchHorasCliente,fetchHoras,clearHoras,getClientesUsuario,actualizarTodosEstadosTarjetas, loginUsuario,limpiarHoras, logoutUsuario, getServicios, getTareas, cargarHoras, 
  actualizarHoras, obtenerHoras, actualizarHorasUsuariologin, rehydrateSession, eliminarHoras,getTareasDefault,setTareas,getTodasTareas};
  
  
  
  

