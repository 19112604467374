import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginPage from './component/login/logininicio';
import HomePage from './component/Home/HomePage';
import Reporte from './component/Reportes/ReportesPages'; // Importa el componente Reporte
import { history } from './history';
import { rehydrateSession } from './actions/index.js'; // Asegúrate de importar la acción rehydrateSession
import PrivateRoute from './utils/PrivateRoute.js';


// cons App es el componente principal de la aplicación donde se definen las rutas de la aplicación para navegar entre las páginas
const App = () => {
  const dispatch = useDispatch();
  
  // Usa el hook useEffect para rehidratar la sesión del usuario al cargar la aplicación
  useEffect(() => {
    const token = localStorage.getItem('token');
    // Si hay un token en el almacenamiento local, rehidrata la sesión del usuario
    if (token) {
      dispatch(rehydrateSession(token));
    }
  }, [dispatch]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/home" component={HomePage} />
        <PrivateRoute path="/reportes" component={Reporte} /> {/* Nueva ruta para el componente Reporte */}
      
      </Switch>
    </Router>
  );
};

export default App;