import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

//en este archivo se define el objeto de historial que se utiliza para navegar entre las rutas de la aplicación
// Crea un objeto de historial de navegación con createBrowserHistory
export const history = createBrowserHistory();

// Crea un componente de orden superior que envuelve un componente con el objeto de historial para que pueda acceder a él a través de las props
//es decir, este componente se encarga de proporcionar el objeto de historial a los componentes de la aplicación, esto hace que 
//sea más fácil acceder al historial de navegación en cualquier parte de la aplicación para redirigir a los usuarios a diferentes rutas.
export const withRouterHistory = Component => props => (
  <Router history={history}>
    <Component {...props} />
  </Router>
);