import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsuarios, logoutUsuario,fetchHoras,fetchTodasHoras,fetchHorasCliente,getTodosClientes,fetchHorasArea,getTodasAreas } from '../../actions/index.js';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import './ModalReportes.css';
import { utils, write } from 'xlsx';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import NavBarHome from '../NavBarHome/NavBarHome.jsx';



import swal from 'sweetalert';


Modal.setAppElement('#root');

const Reporte = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.usuario);
  const usuarios = useSelector(state => state.usuarios);
  const areas = useSelector(state => state.Repoteareas);
  const horasReporte = useSelector(state => state.horasReporte);
  const clientesReportes = useSelector(state => state.clientesReporte);
  const [usuarioInput, setUsuarioInput] = useState(''); // Estado para el valor del input de búsqueda
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [modalVisible, setModalVisible] = useState(true);
  const [mostrarFechas, setMostrarFechas] = useState(false);
  const [mostrarUsuarios, setMostrarUsuarios] = useState(false); // Estado para controlar la visibilidad del buscador de usuarios
  const [debeExportar, setDebeExportar] = useState(false);
  const [botonPresionado, setBotonPresionado] = useState(null);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [mostrarClientes, setMostrarClientes] = useState(false); // Estado para controlar la visibilidad del buscador de clientes
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState(false);
  const [areaSeleccionada, setAreaSeleccionada] = useState(null);
  const [mostrarAreas, setMostrarAreas] = useState(false); // Estado para controlar la visibilidad del selector de áreas
 
// Función para manejar el cierre de sesión en la NavBar desde la pagina de reportes
  const handleLogout = () => {
    dispatch(logoutUsuario());
    history.push('/login');
  };

// este useHistoy es para redireccionar a la pagina de login
const history = useHistory();

// Función para manejar el cierre del modal
const handleCloseModal = () => {
  setModalVisible(false);// Ocultar el modal
  setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
  setFechaDesde(null);// Resetear el estado de la fecha desde
  setFechaHasta(null);// Resetear el estado de la fecha hasta
 
};

// Función para abrir el modal
const abrirModal = () => {
  setModalVisible(true);
};

// useEffect para cargar los usuarios, clientes y áreas al montar el componente
  useEffect(() => {
    dispatch(fetchUsuarios());  // Obtener todos los usuarios al cargar el componente
    dispatch(getTodosClientes()); // Obtener todos los clientes al cargar el componente
    dispatch(getTodasAreas());// Obtener todas las áreas al cargar el componente
  
    console.log( areas.areas);
 
  }, [dispatch]);

// Función para manejar el clic en el botón "Usuario"
  const handleUsuarioClick = () => {
    setBotonPresionado('usuario');// Establecer el botón presionado en "usuario"
    setMostrarUsuarios(true); // Mostrar el buscador de usuarios al hacer clic en el botón "Usuario"
    setMostrarClientes(false); // Ocultar el buscador de clientes
    setMostrarAreas(false); // Ocultar el selector de áreas
    setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
    setMostrarFechas(false);// Ocultar el selector de fechas
    setFechaDesde(null);// Resetear el estado de la fecha desde
    setFechaHasta(null);// Resetear el estado de la fecha hasta
  };

// Función para manejar el clic en el botón "Área"
  const handleAreaClick = () => {
    setBotonPresionado('area');// Establecer el botón presionado en "area"
    setMostrarFechas(false);// Ocultar el selector de fechas
    setMostrarAreas(true); // Mostrar el selector de áreas
    setMostrarClientes(false); // Ocultar el selector de clientes
    setMostrarUsuarios(false); // Ocultar el selector de usuarios
    setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
    setFechaDesde(null);// Resetear el estado de la fecha desde
    setFechaHasta(null);// Resetear el estado de la fecha hasta
  };

// Función para manejar la selección de área en el select de áreas
  const handleAreaSeleccionada = (selectedOption) => {
    // Buscar el área seleccionada en el array de áreas
    const areaSeleccionada = areas.areas.find(a => `${a.nombre}` === selectedOption.value);
    console.log(areaSeleccionada)
    setAreaSeleccionada(areaSeleccionada.id);// Establecer el área seleccionada en el estado
    setMostrarFechas(true);// Mostrar el selector de fechas
    setMostrarAreas(false); // Ocultar el selector de áreas al seleccionar una
  };
  
// Función para manejar la selección de usuario en el select de usuarios
  const handleUsuarioSeleccionado = (selectedOption) => {
    // Verificar si se selecciona "Todos los usuarios" en la lista
    if (selectedOption.value === 'Todos los usuarios') {
      setUsuarioSeleccionado('Todos los usuarios');// Establecer "Todos los usuarios" en el estado
      setMostrarFechas(true);// Mostrar el selector de fechas
      setMostrarUsuarios(false); // Ocultar el buscador de usuarios al seleccionar uno
    } else {
      //si se selecciona un usuario en la lista, buscar el usuario seleccionado en el array de usuarios
      const usuarioSeleccionado = usuarios.find(u => `${u.nombre} ${u.apellidos}` === selectedOption.value);
      setUsuarioSeleccionado(usuarioSeleccionado.id);// Establecer el usuario seleccionado en el estado
      setMostrarFechas(true);// Mostrar el selector de fechas
      setMostrarUsuarios(false); // Ocultar el buscador de usuarios al seleccionar uno
    }
  };

// Función para manejar la selección de cliente en el select de clientes
  const handleClienteSeleccionado = (selectedOption) => {
    // Buscar el cliente seleccionado en el array de clientes
    const clienteSeleccionado = clientesReportes.find(c => `${c.nombre}` === selectedOption.value);
  
    setClienteSeleccionado(clienteSeleccionado.id);// Establecer el cliente seleccionado en el estado
    setMostrarFechas(true);// Mostrar el selector de fechas
    setMostrarClientes(false); // Ocultar el buscador de clientes al seleccionar uno
  };

// useEffect es para verificar si debeExportar es true y si horasReporte tiene datos y llamar a la función de exportación
  useEffect(() => {
    // Verificar si debeExportar es true y si horasReporte tiene datos
    if (debeExportar && horasReporte && horasReporte.length > 0) {
      // Llamar a la función de exportación y pasar horasReporte como argumento
      exportarAExcel(horasReporte) 
        .then(() => {
          setDebeExportar(false); // Resetear el estado para la próxima exportación
          swal.close(); // Cerrar la alerta aquí
        });
    }
  }, [horasReporte, debeExportar]);
  
//funcion de exportar que se encarga de llamar a las funciones de 
//fetchHorasArea, fetchHorasCliente y fetchHoras dependiendo del boton presionado
  const exportar = async () => {
    // Mostrar alerta de carga
    swal({
      title: "Exportando...",
      text: "Por favor espera mientras se preparan los datos.",
      icon: "info",
      button: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  // Declarar horasReporte 
    let horasReporte = []; 
    // Intentar exportar los datos
      try {
        // Verificar qué botón se presionó
        if (botonPresionado === 'area') {
          // Llamar a fetchHorasArea con el área seleccionada, la fecha desde y la fecha hasta
          horasReporte = await dispatch(fetchHorasArea(areaSeleccionada, fechaDesde, fechaHasta));
          // Verificar si horasReporte está vacío
          //es decir si no hay horas cargadas para el area seleccionada en el rango de fechas seleccionado en la base de datos
          if (horasReporte && horasReporte.length === 0) {
            setTimeout(() => swal("No hay horas cargadas para esta área en el rango de fechas seleccionado.", { icon: "error" }), 2000);
          } else {
            // Una vez que los datos estén disponibles, establecer debeExportar en true
            setDebeExportar(true);
          }

        // Si se presionó el botón "Cliente"
        } else if (botonPresionado === 'cliente') {
          // Llamar a fetchHorasCliente con el cliente seleccionado, la fecha desde y la fecha hasta
          horasReporte = await dispatch(fetchHorasCliente(clienteSeleccionado, fechaDesde, fechaHasta));
          // Verificar si horasReporte está vacío
          if (horasReporte && horasReporte.length === 0) {
            setTimeout(() => swal("No hay horas cargadas para este cliente en el rango de fechas seleccionado.", { icon: "error" }), 2000);
          } else {
            // Una vez que los datos estén disponibles, establecer debeExportar en true
            setDebeExportar(true);
          }

         // Si se presionó el botón "Usuario"
        } else if (botonPresionado === 'usuario') {
        // Si se selecciona "Todos los usuarios", llama a fetchTodasHoras
      if (usuarioSeleccionado === 'Todos los usuarios') {
        horasReporte = await dispatch(fetchTodasHoras(fechaDesde, fechaHasta));
        //sino llama a fetchHoras con el usuario seleccionado, la fecha desde y la fecha hasta
      } else {
        horasReporte = await dispatch(fetchHoras(usuarioSeleccionado, fechaDesde, fechaHasta));
      }
      // Verificar si horasReporte está vacío para el usuario seleccionado
      if (horasReporte && horasReporte.length === 0) {
        setTimeout(() => swal("No hay horas cargadas para este usuario en el rango de fechas seleccionado.", { icon: "error" }), 2000);
      } else {
        // Una vez que los datos estén disponibles, establecer debeExportar en true
        setDebeExportar(true);
      }
        }
    
     } catch (error) {
      console.error(error);
      // Verificar si hay un error de respuesta
      if (error.response) {
        if (error.response.status === 403) {
          setTimeout(() => swal(error.message, { icon: "error" }), 2000);
        } else if (error.response.status === 404) {
          setTimeout(() => swal(error.message, { icon: "error" }), 2000);
        } else if (error.response.status === 500) {
          setTimeout(() => swal("Error del servidor", "Hubo un problema en el servidor al procesar tu solicitud. Por favor, intenta de nuevo más tarde.", "error"), 2000);
        } else {
          setTimeout(() => swal("Oh no!", "La solicitud de exportar falló, por favor contactar al administrador!", "error"), 2000);
        }
      } else {
        setTimeout(() => swal("Oh no!", "La solicitud de exportar falló, por favor contactar al administrador!", "error"), 2000);
      }
    }
  };
// Función para manejar el clic en el botón "Volver"
  const handleVolverClick = () => {
    setMostrarFechas(false); // Ocultar el selector de fechas
    setFechaDesde(null);// Resetear el estado de la fecha desde
    setFechaHasta(null);// Resetear el estado de la fecha hasta
    setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
  };

// Función para exportar los datos a un archivo de Excel
  const exportarAExcel = () => {
    // Devolver una nueva promesa para manejar la exportación
    // Es decir, la exportación se realizará de forma asíncrona entoces se debe devolver una promesa para manejarla
    // donde resolve se ejecuta cuando la exportación es exitosa y reject cuando hay un error
    return new Promise((resolve, reject) => {
      // Esperar 2 segundos antes de comenzar la exportación
      setTimeout(() => {
        // Intentar exportar los datos
        try {
         // Crear un nuevo libro de trabajo
         const wb = utils.book_new();
         // Convertir los datos a un formato que xlsx pueda entender
            const ws_data = horasReporte.map(horasReporte => ([
              horasReporte.fechaCarga,
              horasReporte.cliente ? horasReporte.cliente.nombre : 'N/A',
              horasReporte.usuario ? `${horasReporte.usuario.nombre} ${horasReporte.usuario.apellidos}` : 'N/A',
              horasReporte.horas,
              horasReporte.descripcion,
              horasReporte.senorityhora,
              horasReporte.servicio ? horasReporte.servicio.nombre : 'N/A',
              horasReporte.tarea ? horasReporte.tarea.nombre : 'N/A',
              botonPresionado === 'area' ? areas.areas.find(a => a.id === areaSeleccionada).nombre : (horasReporte.usuario && horasReporte.usuario.areas ? horasReporte.usuario.areas.map(area => area.nombre).join(', ') : 'N/A'), // Añadir la columna "Área"
           ]));
          // Añadir títulos a la primera fila
          ws_data.unshift(['Fecha de Carga','Cliente','Usuario', 'Horas','Descripción', 'Seniority Hora', 'Servicio', 'Tarea', 'Área']); // Añadir el título "Área"

          // Crear una nueva hoja de trabajo a partir de los datos
          const ws = utils.aoa_to_sheet(ws_data);
        
          // Añadir la hoja de trabajo al libro de trabajo
          utils.book_append_sheet(wb, ws, "Horas");
        
          // Escribir el libro de trabajo en un archivo y descargarlo
          const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
          saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'horas.xlsx');
  
          // Si todo va bien, resolvemos la promesa
          resolve();
          setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
          setFechaDesde(null);// Resetear el estado de la fecha desde
          setFechaHasta(null);// Resetear el estado de la fecha hasta
        } catch (error) {
          // Si hay un error, rechazamos la promesa
          reject(error);
        }
      }, 2000); // Esperar 2 segundos antes de comenzar la exportación
    });
  };
  // Esta función es necesaria para convertir el archivo a un formato que el navegador pueda entender
  //Es decir, convierte una cadena en un búfer de bytes para poder descargar el archivo
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);// Crea un búfer de bytes en la longitud de la cadena
    const view = new Uint8Array(buf);// Crea una vista de 8 bits sin signo en el búfer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;// Itera sobre la cadena y convierte cada carácter en un código ASCII de 8 bits
    return buf;// Devuelve el búfer
  }

 // Función para manejar el clic en el botón "Cliente"
const handleClienteClick = () => {
  setMostrarClientes(true); // Mostrar el buscador de clientes
  setMostrarUsuarios(false); // Ocultar el buscador de usuarios
  setMostrarAreas(false); // Ocultar el selector de áreas
  setBotonPresionado('cliente');// Establecer el botón presionado en "cliente"
  setMostrarFechas(false);// Ocultar el selector de fechas
  setFechasSeleccionadas(false);// Resetear el estado de las fechas seleccionadas
  setFechaDesde(null);// Resetear el estado de la fecha desde
  setFechaHasta(null);// Resetear el estado de la fecha hasta
};

// Función para verificar si las fechas desde y hasta están seleccionadas
const verificarFechas = (fechaDesde, fechaHasta) => {
    if (fechaDesde && fechaHasta) {
      setFechasSeleccionadas(true);
    } else {
      setFechasSeleccionadas(false);
    }
  };

// Renderiza el componente
  return (
<div className="reports-page">
  <NavBarHome style={{ position: 'fixed', top: 0, width: '100%' }} user={user} handleLogout={handleLogout}/> 
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', marginTop: '1px' }}>  
    <button className="open-modal-button" onClick={abrirModal}  style={{ opacity: modalVisible ? 0: 1 }}>Crear reporte</button>
  </div>
  <Modal 
    isOpen={modalVisible} 
    onRequestClose={handleCloseModal} 
    className="Modal__content" 
    overlayClassName="Modal__overlay"
>
           <div className='btnsReportes'> <button className="modal-button" onClick={handleUsuarioClick} >Usuario</button>
            <button className="modal-button" onClick={handleClienteClick} >Cliente</button>
            <button className="modal-button" onClick={handleAreaClick} >Area</button>
            </div>
        {mostrarUsuarios && usuarios && (
          <Select
          className="modal-select"
          value={usuarioInput}
          onChange={(selectedOption) => handleUsuarioSeleccionado(selectedOption)}
          options={[{ value: 'Todos los usuarios', label: 'Todos los usuarios' }, ...usuarios.map(usuario => ({ value: `${usuario.nombre} ${usuario.apellidos}`, label: `${usuario.nombre} ${usuario.apellidos}` }))]}
          placeholder="Usuarios"
          openMenuOnClick={false}
          isSearchable
  styles={{
    control: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto, sans-serif',
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255)',
      outline: 'none',
      border: state.isFocused ? '1px solid black' : 'none', 
      boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', 
      borderBottom: '1px solid #52df8c',
      fontSize: '12px',
      gap: '10px',
      height: '42px',
      padding: '1px',
      width: '250px',
      letterSpacing: '1px',
      transition: '0.5s',
      ':hover': {
        border: state.isHovered ? '1px solid #52df8c' : '1px solid #52df8c', // Cambia el color del borde cuando el mouse está sobre el componente
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'black',
      borderRadius: '1px',
      overflow: 'auto', // Cambiado de 'scroll' a 'auto'
      scrollbarWidth: 'none',
      maxHeight: '245px', // Ajusta esta línea para cambiar la altura máxima de la lista desplegable
      zIndex: '1',
      border: '0.01px solid black',
      width: '250px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isHighlighted ? 'blue' : 'black',
      fontSize: '10px',
      color: 'white'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
  }}
          />
        )}
        {mostrarClientes && clientesReportes && (
  <Select
    className="modal-select"
    value={clienteSeleccionado}
    onChange={(selectedOption) => handleClienteSeleccionado(selectedOption)}
    options={clientesReportes.map(cliente => ({ value: `${cliente.nombre}`, label: `${cliente.nombre}` }))}
    placeholder="Clientes"
    openMenuOnClick={false}
    isSearchable
    styles={{
      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: 'transparent',
        color: 'rgba(255, 255, 255)',
        outline: 'none',
        border: state.isFocused ? '1px solid black' : 'none', 
        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', 
        borderBottom: '1px solid #52df8c',
        fontSize: '12px',
        gap: '10px',
        height: '42px',
        padding: '1px',
        width: '250px',
        letterSpacing: '1px',
        transition: '0.5s',
        ':hover': {
          border: state.isHovered ? '1px solid #52df8c' : '1px solid #52df8c',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(0, 0, 0, 1)',
        color: 'black',
        borderRadius: '1px',
        overflow: 'auto', // Cambiado de 'scroll' a 'auto'
        scrollbarWidth: 'none',
        maxHeight: '245px', // Ajusta esta línea para cambiar la altura máxima de la lista desplegable
        zIndex: '1',
        border: '0.01px solid black',
        width: '250px',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isHighlighted ? 'blue' : 'black',
        fontSize: '10px',
        color: 'white'
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      input: (provided) => ({
        ...provided,
        color: 'white',
      }),
    }}
  />
)}
{mostrarAreas && areas && (
  <Select
    className="modal-select"
    value={areaSeleccionada}
    onChange={(selectedOption) => handleAreaSeleccionada(selectedOption)}
    options={areas.areas.map(area => ({ value: `${area.nombre}`, label: `${area.nombre}` }))}
    placeholder="Areas"
    openMenuOnClick={false}
    isSearchable
    styles={{
      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: 'transparent',
        color: 'rgba(255, 255, 255)',
        outline: 'none',
        border: state.isFocused ? '1px solid black' : 'none', 
        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', 
        borderBottom: '1px solid #52df8c',
        fontSize: '12px',
        gap: '10px',
        height: '42px',
        padding: '1px',
        width: '250px',
        letterSpacing: '1px',
        transition: '0.5s',
        ':hover': {
          border: state.isHovered ? '1px solid #52df8c' : '1px solid #52df8c',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: 'rgba(0, 0, 0, 1)',
        color: 'black',
        borderRadius: '1px',
        overflow: 'auto', // Cambiado de 'scroll' a 'auto'
        scrollbarWidth: 'none',
        maxHeight: '245px', // Ajusta esta línea para cambiar la altura máxima de la lista desplegable
        zIndex: '1',
        border: '0.01px solid black',
        width: '250px',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isHighlighted ? 'blue' : 'black',
        fontSize: '10px',
        color: 'white'
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      input: (provided) => ({
        ...provided,
        color: 'white',
      }),
    }}
  />
)}
  {mostrarFechas && (
    <>
     <div className="modal-dates">
      <label>Desde:</label>
      <input className="modal-input" type="date" onChange={(event) => { setFechaDesde(event.target.value); verificarFechas(event.target.value, fechaHasta); }} required />
      <label>Hasta:</label>
      <input className="modal-input" type="date" onChange={(event) => { setFechaHasta(event.target.value); verificarFechas(fechaDesde, event.target.value); }} required/>
    </div>
    <div className="modal-buttons">
      <button className="modal-button" onClick={exportar} disabled={!fechasSeleccionadas}>Exportar</button>
      <button className="modal-button" onClick={handleVolverClick}>Volver</button>
      <button className="modal-button" onClick={handleCloseModal}>Cerrar</button>
    </div>
    </>
  )}
</Modal>
    </div>
  );
};

export default Reporte;

