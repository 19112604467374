// Componente FormularioCargaHora
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getClientesUsuario, limpiarServicios,getServicios, getTareas, cargarHoras, actualizarHoras, actualizarTodosEstadosTarjetas, getTareasDefault } from '../../actions/index.js';
import './FormularioCargaHora.css';
import Select from 'react-select';
import swal from 'sweetalert';

const FormularioCargaHora = ({ fecha, onSubmit, onClose, horas, isEditing,onFormSubmit }) => {
  const dispatch = useDispatch();
  const clientes = useSelector(state => state.clientes);
  const servicios = useSelector(state => state.servicios);
  const tareas = useSelector(state => state.tareasToservicio);
  const usuario = useSelector(state => state.session);
  const serviciosCargando = useSelector(state => state.serviciosCargando);
  const tareasCargando = useSelector(state => state.tareasCargando);
  const [clienteId, setClienteId] = useState('');
  const [servicioId, setServicioId] = useState('');
  const [tareaId, setTareaId] = useState('');
  const [horasTrabajadas, setHorasTrabajadas] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [fechaCarga, setFechaCarga] = useState(fecha);
  const isMounted = useRef(true);
  const tareasDefault = useSelector(state => state.tareasDefault);
  const [tareasMostradas, setTareasMostradas] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [enviandoFormulario, setEnviandoFormulario] = useState(false);
  
// Este useEffect se ejecuta una vez cuando el componente se monta. Se encarga de obtener los clientes del usuario, las tareas por defecto y actualizar todos los estados de las tarjetas.
  useEffect(() => {
    dispatch(getClientesUsuario());
    dispatch(getTareasDefault());
    dispatch(actualizarTodosEstadosTarjetas());
  }, []); //

// Este useEffect se ejecuta cada vez que cambia servicioId, tareas o tareasDefault. Se encarga de establecer las tareas mostradas en función del servicio seleccionado.
  useEffect(() => {
    if (servicioId === '') {
      setTareasMostradas(tareasDefault);
    } else {
      setTareasMostradas(tareas);
    }
  }, [servicioId, tareas, tareasDefault]);

// Este useEffect se ejecuta cada vez que cambian horas o clientes. Se encarga de establecer los valores de los campos del formulario en función de las horas seleccionadas. 
//es decir, para saber si se está editando o cargando un nuevo registro. 
  useEffect(() => {
    if (horas) {
      setClienteId(horas.clienteId || '');
      setServicioId(horas.servicioId || '');
      setTareaId(horas.tareaId || '');
      setHorasTrabajadas(horas.horas || '');
      setDescripcion(horas.descripcion || '');
      const cliente = clientes.find(cliente => cliente.id === horas.clienteId);
      if (cliente) {
        setInputValue(cliente.nombre); // Muestra el nombre del cliente del registro que se está actualizando
        dispatch(getServicios(cliente.id)); // Carga los servicios para el cliente seleccionado
       
      }
    }
  }, [horas, clientes]);

// Este useEffect se ejecuta cada vez que cambia fecha. Se encarga de establecer la fecha de carga.
  useEffect(() => {
    setFechaCarga(fecha);
  }, [fecha]);

// Este useEffect se ejecuta cuando el componente se monta y se desmonta. Se encarga de limpiar los servicios cuando el componente se desmonta.
// Esto es necesario para evitar que los servicios de un cliente anterior se muestren cuando se carga un nuevo registro.
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      dispatch(limpiarServicios()); // Limpia los servicios cuando el componente se desmonta
    };
  }, []);

// Esta función se encarga de manejar el envío del formulario.
// Realiza la validación de las horas trabajadas, el cliente y la tarea seleccionados.
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validación de horas
    if (horasTrabajadas <= 0) {
      alert('Las horas trabajadas deben ser mayores a cero.');
      return;
    }
  
// Validación de cliente o tarea por defecto
     if (!clienteId && tareaId === '') {
  swal({
    title: "Oops...",
    text: "Debe seleccionar un cliente o una tarea por defecto.",
    icon: "error",
    className: "alertaEliminarr", // Agrega la clase CSS a la alerta
    buttons: {
      confirm: {
        text: "OK",
        className: "alertaBtn1" // Agrega la clase CSS al botón
      }
    }
  });
  return;
     } else if (clienteId && tareaId === '') {
      swal({
      title: "Oops...",
      text: "Debe seleccionar una tarea.",
      icon: "error",
     className: "alertaEliminarr", // Agrega la clase CSS a la alerta
      buttons: {
      confirm: {
        text: "OK",
         className: "alertaBtn1" // Agrega la clase CSS al botón
       }
      }
      });
         return;
       }
       // Si se están actualizando horas, llama a handleActualizarHoras. 
       //Si se están cargando nuevas horas, llama a handleCargarHoras.
    if (horas) {
      await handleActualizarHoras(e);
     } else {
      await handleCargarHoras(e);
      dispatch(limpiarServicios()); // Limpia los servicios después de cargar el nuevo registro
      setServicioId('');
     }
    // Llama a la función de callback para manejar la actualización del estado de carga en CardDia
   // onFormSubmit();
  };

 // Esta función se ejecuta cuando cambia el cliente seleccionado.
 // Se encarga de obtener los servicios del cliente seleccionado.
  const handleClienteChange = (clientId) => {
    setClienteId(clientId);
   
    if (clientId) {
      dispatch(getServicios(clientId));
     
    }
  };

  // Esta función se encarga de eliminar los duplicados de un array de objetos en función de una clave.
  // Se utiliza para eliminar los clientes duplicados en el select de clientes.
  const eliminarDuplicados = (arr, key) => {
    return arr.reduce((item, i) => {
      const existente = item.find(j => j[key] === i[key]);
      if (!existente) {
        item.push(i);
      }
      return item;
    }, []);
  };

  // Obtiene los clientes sin duplicados
  const clientesSinDuplicados = eliminarDuplicados(clientes, 'id');

  // Crea las opciones de clientes para el select
  const opcionesClientes = clientesSinDuplicados.map(cliente => ({ value: cliente.id, label: cliente.nombre }));

// Esta función se ejecuta cuando cambia el servicio seleccionado.
// Se encarga de obtener las tareas del servicio seleccionado.
  const handleServicioChange = (e) => {
    setServicioId(e.target.value);
    if (e.target.value === '') {
      setTareaId(tareasDefault);
    } else {
      dispatch(getTareas(e.target.value));
    }
  };

// Esta función se ejecuta cuando cambia la tarea seleccionada.
  const handleTareaChange = (e) => {
    setTareaId(e.target.value);
  };

 // Este useEffect se ejecuta cada vez que cambian los servicios.
 // Se encarga de limpiar el servicio seleccionado.
 useEffect(() => {
  setServicioId('');
}, [servicios]);

// Esta función se encarga de cargar las horas en la base de datos.
const handleCargarHoras = async (e) => {
  // Evita que se envíe el formulario más de una vez
  e.preventDefault();
  // Cambia el estado de enviandoFormulario a true
  if (isMounted.current) {
    setEnviandoFormulario(true);
  }
  // Crea un objeto con los datos del formulario
  const datosFormulario = { clienteId, servicioId, tareaId, horasTrabajadas, descripcion, usuarioId: usuario.id, senorityhora: usuario.perfil, fechaCarga: fecha, cardId: fecha };
  // Intenta cargar las horas en la base de datos
  try {
    // Llama a la acción cargarHoras con los datos del formulario
    const response = await dispatch(cargarHoras(datosFormulario));
    // Si la respuesta y la respuesta.data.id existen, establece el id del registro en datosFormulario
    if (response && response.data && response.data.id) {
      datosFormulario.id = response.data.id;
    }

    dispatch(actualizarTodosEstadosTarjetas());// Actualiza todos los estados de las tarjetas
    dispatch(limpiarServicios()); // Limpia los servicios después de cargar el nuevo registro
    setServicioId('');// Limpia el servicio seleccionado
    onSubmit(datosFormulario);// Llama a la función de callback onSubmit con los datos del formulario
    onClose();// Cierra el formulario
    
  } catch (error) {
    console.error(error);
  } finally {
    // Cambia el estado de enviandoFormulario a false
    // para permitir que se envíe el formulario nuevamente
    if (isMounted.current) {
      setEnviandoFormulario(false);
    }
  }
};

// Esta función se encarga de actualizar las horas en la base de datos.
const handleActualizarHoras = async (e) => {
  // Evita que se envíe el formulario más de una vez
    e.preventDefault();
  // Crea un objeto con los datos del formulario
    const datosFormulario = { clienteId, servicioId, tareaId, horasTrabajadas, descripcion, usuarioId: usuario.id, senorityhora: usuario.perfil, fechaCarga: fecha };
  // Si horas y horas.id existen, actualiza las horas
    if (horas && horas.id) {
      const idOriginal = horas.id;// Guarda el id original de las horas
      // Llama a la acción actualizarHoras con los datos del formulario y el id de las horas
      const response = await dispatch(actualizarHoras({ ...datosFormulario, id: horas.id }));
      // Si la respuesta y la respuesta.id existen, establece el id del registro en datosFormulario
      const id = response && response.id ? response.id : idOriginal;
      onSubmit({ ...datosFormulario, id, idOriginal });// Llama a la función de callback onSubmit con los datos del formulario
      setServicioId('');// Limpia el servicio seleccionado
      onClose(); // Cierra el formulario
    // Si horas o horas.id no existen, muestra un mensaje de error
    } else {
      console.log('horas o horas.id es undefined');
    }
  };

// Renderiza el formulario
return (
   //se agrega el formulario de carga de horas
<form className='formCarga' onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <button className='btnCloseCarga' type="button" onClick={onClose}>
        <img src={require('../../images/cruz-verde.svg').default} alt="Cerrar" />
      </button>

    {/* Se renderiza el select de clientes con
      todas sus configuraciones y estilos*/}
    <Select
    value={opcionesClientes.find(opcion => opcion.value === clienteId)}
      onChange={opcion => handleClienteChange(opcion.value)}
      options={opcionesClientes}
      placeholder="Busque un cliente"
      openMenuOnClick={false}
      isSearchable
      styles={{
      control: (provided, state) => ({
      ...provided,
      fontFamily: 'Roboto, sans-serif',
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255)',
      outline: 'none',
      border: state.isFocused ? '1px solid black' : 'none', 
      boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', 
      borderBottom: '1px solid #52df8c',
      fontSize: '12px',
      gap: '10px',
      height: '42px',
      padding: '1px',
      width: '250px',
      letterSpacing: '1px',
      transition: '0.5s',
      ':hover': {
        border: state.isHovered ? '1px solid #52df8c' : '1px solid #52df8c', // Cambia el color del borde cuando el mouse está sobre el componente
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'black',
      borderRadius: '1px',
      overflow: 'auto', // Cambiado de 'scroll' a 'auto'
      scrollbarWidth: 'none',
      maxHeight: '245px', // Ajusta esta línea para cambiar la altura máxima de la lista desplegable
      zIndex: '1',
      border: '0.01px solid black'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isHighlighted ? 'blue' : 'black',
      fontSize: '10px',
      color: 'white'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
       }}
    />

    {/* Se renderiza el select de servicios */}
    <select onChange={handleServicioChange} style={{ margin: '10px' }}>
        <option className='listServ' value="">Seleccione un servicio</option>
        {/* Si serviciosCargando es true, muestra un mensaje de carga */}
        {serviciosCargando ? (
           
          <option>Cargando servicios...</option>
        ) : (
          // Si servicios es un array, mapea los servicios y renderiza las opciones
          Array.isArray(servicios) ? servicios.map(servicio => (
            <option key={servicio.id} value={servicio.id}>{servicio.nombre}</option>
          )) :
          // Si servicios no es un array, muestra un mensaje de error
          (<option>Error: los servicios no son un array.</option>)
          
        )}
       
    </select>

    {/* Se renderiza el select de tareas */}
    <select onChange={handleTareaChange} style={{ margin: '10px' }} >
      <option className='listTarea' value="">Seleccione una tarea</option>
      {tareasCargando ? (
       <option>Cargando tareas...</option>
        ) : (
         Array.isArray(tareasMostradas) ? (
          tareasMostradas.map((tarea, index) => (
       <option key={index} value={tarea?.id}>{tarea?.nombre}</option>
          ))
         ) : (
         <option>Error: las tareas no son un array.</option>
         ))}
    </select>


  <div className='inpFormHoras'>
     <input placeholder='Horas..' type="number" min="0.01" step="0.01" value={horasTrabajadas} onChange={e => setHorasTrabajadas(e.target.value)} required />
  </div>
  <div className='inpFormHoras'>
    <input placeholder='Descripcion..' type="text" value={descripcion} onChange={e => setDescripcion(e.target.value)} required />
  </div>
      {!horas && <button className='bntCargar' type="submit" disabled={enviandoFormulario}>Cargar horas</button>}
      {horas && <button className='bntAct' type="submit">Actualizar horas</button>}
</form>
  );
};

export default FormularioCargaHora;