import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutUsuario, obtenerHoras } from '../../actions/index.js';
import Semana from '../cardSemana/CardSemana.jsx';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.jsx'; // Importa el componente LoadingSpinner
import './homepage.css';
import NavBarHome from '../NavBarHome/NavBarHome.jsx';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const user = useSelector(state => state.usuario);

  useEffect(() => {
    // Cargar los datos necesarios al montar el componente
    const cargarDatos = async () => {
      try {
        await dispatch(obtenerHoras()); // Cargar horas
        // Agrega un retraso artificial de 2 segundos (2000 milisegundos)
        console.log('este es el primero');
        setTimeout(() => {
          setLoading(false); // Cambiar el estado de carga a false cuando los datos estén listos
        }, 500);
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setLoading(false); // En caso de error, también cambia el estado de carga a false para evitar que el indicador de carga permanezca para siempre
      }
    };
  
    cargarDatos();
  }, [dispatch]); //
// Función para cerrar sesión
  const handleLogout = () => {
    dispatch(logoutUsuario());
    history.push('/login');
  };

// Renderiza el componente
  return (
    <div className="home-page">
      <NavBarHome user={user} handleLogout={handleLogout} /> {/* Aquí se agrega la NavBarHome */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Semana />
      )}
    </div>
  );
};

export default HomePage;