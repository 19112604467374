import axios from 'axios';
import { store } from './redux/store'; // importa tu store de Redux
import { logoutUsuario } from './actions/index'; // importa la acción de logout
import { history } from './history'; // importa tu objeto de historial


//en este archivo se define un cliente de axios que se encarga de manejar las peticiones HTTP a la API.
const axiosClient = axios.create({
  baseURL: 'https://xforce-108ef308b09b.herokuapp.com/', // reemplaza esto con la URL base de tu API
});

// Interceptor para agregar el token de autenticación a las peticiones
axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) { // si el status es 401 (no autorizado), el token probablemente expiró
      store.dispatch(logoutUsuario()).then(() => { // despacha la acción de logout y espera a que se complete
        history.push('/login'); // forzar una recarga completa de la página para que el usuario sea redirigido al login
        
      });
    }
    return Promise.reject(error);
  }
);

export default axiosClient;